<template>
  <Head v-if="costMin">
    <title>
      Купить двухкомнатную квартиру в новостройке на Ботаническом саду в Москве
      от застройщика | ЖК VERY
    </title>
    <meta
      name="description"
      :content="
        'Продажа новых двухкомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:title"
      content="Купить двухкомнатную квартиру в новостройке на Ботаническом саду в Москве от застройщика | ЖК VERY"
    />
    <meta
      property="og:description"
      :content="
        'Продажа новых двухкомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>

  <FlatsSearch
    h1="Двухкомнатные квартиры"
    pre-rooms="2"
    @costMin="onCostMinEmits"
  >
    <template #text>
      <div class="text mt-5">
        <p>
          Покупка собственной квартиры&nbsp;&mdash; это ответственный
          и&nbsp;важный шаг в&nbsp;жизни каждого, а&nbsp;также сложное
          и&nbsp;затратное мероприятие. Поэтому при выборе недвижимости следует
          учесть множество факторов: площадь жилья, количество комнат,
          расположение жилого комплекса и&nbsp;его инфраструктуру, концепт
          и&nbsp;архитектуру проекта. От&nbsp;этого зависит не&nbsp;только
          комфорт проживания, но&nbsp;и&nbsp;возможность выгодно продать жилье
          в&nbsp;будущем в&nbsp;случае необходимости.
        </p>
        <p>
          ЖК&nbsp;Very предлагает купить двухкомнатную квартиру
          у&nbsp;Ботанического сада. Это оптимальное по&nbsp;цене
          и&nbsp;характеристикам решение, которое подойдет большому количеству
          потенциальных покупателей. Рассмотрим, почему.
        </p>
        <h2 class="h4">
          Почему стоит купить 2-комнатную квартиру у&nbsp;Ботанического сада
          в&nbsp;ЖК&nbsp;Very
        </h2>
        <p>
          Комфорт и&nbsp;удовольствие от&nbsp;проживания в&nbsp;квартире
          напрямую зависит от&nbsp;жилого комплекса, в&nbsp;котором она
          расположена. ЖК&nbsp;Very&nbsp;&mdash; это жемчужина, расположенная
          рядом с&nbsp;Ботаническим садом и&nbsp;районом Марфино.
        </p>
        <p>
          Владелец недвижимости вместе с&nbsp;комфортной двухкомнатной квартирой
          получает такие опции:
        </p>
        <ul>
          <li>
            Развитая инфраструктура. Более&nbsp;77% территории ЖК&nbsp;&mdash;
            это деревья и&nbsp;кустарники, специально высаженные с&nbsp;целью
            создания комфортной атмосферы для жителей. Кроме того, доступны
            детские площадки и&nbsp;места для занятий спортом, есть собственный
            фитнес-центр и&nbsp;коворкинг для бизнес-мероприятий.
          </li>
          <li>
            Концепция &laquo;без границ&raquo;. При создании архитектурного
            проекта специалисты руководствовались концепцией открытости
            и&nbsp;отсутствия границ, что воплотилось в&nbsp;удобных планировках
            холлов и&nbsp;квартир, а&nbsp;также огромных окнах, из&nbsp;которых
            открывается потрясающий вид на&nbsp;окрестности.
          </li>
          <li>
            Широкий выбор вариантов планировки. В&nbsp;продаже доступны
            2-комнатные квартиры разной площади и&nbsp;с&nbsp;разной
            конфигурацией комнат. Можно купить двухкомнатную квартиру
            в&nbsp;районе Ботанического сада с&nbsp;видом на&nbsp;Останкинскую
            телебашню, главный Ботанический сад и&nbsp;Фондовую Оранжерею, Малый
            ботанический сад.
          </li>
          <li>
            Удачное расположение. Несмотря на&nbsp;то, что ЖК&nbsp;Very
            расположен в&nbsp;окружении зеленых насаждений, он&nbsp;находится
            практически в&nbsp;сердце Москвы. Из&nbsp;него легко попасть
            в&nbsp;деловые районы города, доехать до&nbsp;крупных торговых
            центров. А&nbsp;знаковые достопримечательности столицы, в&nbsp;том
            числе Останкинская телебашня или ВДНХ находятся в&nbsp;шаговой
            доступности.
          </li>
          <li>
            Разные условия оплаты. Стоимость квартир в&nbsp;ЖК&nbsp;Very зависит
            от&nbsp;площади и&nbsp;указана на&nbsp;сайте. Благодаря большому
            выбору вариантов купить 2-комнатную квартиру у&nbsp;Ботанического
            сада могут люди с&nbsp;разным уровнем дохода. Платить всю сумму
            сразу не&nbsp;обязательно. Застройщик предлагает несколько вариантов
            оплаты, в&nbsp;том числе доступная ипотека от&nbsp;банков-партнеров,
            рассрочка платежа с&nbsp;уплатой&nbsp;20% первого взноса,
            а&nbsp;также Trade-In с&nbsp;возможностью оплаты покупки после
            продажи собственного старого жилья.
          </li>
          <li>
            Высокая ликвидность. Одним из&nbsp;преимуществ 2-комнатных квартир
            в&nbsp;жилом комплексе Very является постоянный спрос на&nbsp;них
            среди покупателей на&nbsp;вторичном рынке. Также ликвидны
            и&nbsp;сдаваемые в&nbsp;аренду квартиры. Это обусловлено удобным
            расположением, внутренней инфраструктурой комплекса и&nbsp;высоким
            качеством строительства.
          </li>
        </ul>
        <h3 class="h4">
          Как купить двухкомнатную квартиру в&nbsp;Марфино у&nbsp;Ботанического
          сада
        </h3>
        <p>
          Для получения консультаций по&nbsp;вопросам приобретения недвижимости,
          а&nbsp;также оформления заявки на&nbsp;покупку выберите вариант
          квартиры в&nbsp;каталоге и&nbsp;заполните электронную заявку. Наши
          менеджеры готовы помочь в&nbsp;выборе и&nbsp;проконсультировать
          по&nbsp;интересующим вопросам.
        </p>
        <p>Контактные данные для связи указаны на&nbsp;сайте.</p>
      </div>
    </template>
  </FlatsSearch>
</template>

<script>
import FlatsSearch from "@/components/FlatsSearch.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    FlatsSearch,
  },

  data() {
    return {
      costMin: null,
    };
  },
  methods: {
    onCostMinEmits(costMin) {
      this.costMin = costMin;
    },
  },
};
</script>
